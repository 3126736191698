.section-one-about{
    position: relative;
}

.header-images {
    display: flex;
    justify-content: center;
}

.mission-statement-container {
    width: 35%;
    margin: auto;
    border: 10px solid white;
    position: absolute;
    top: 18%;
    left: 33%;
}
    
.mission-statement {
    background-color: white;
    margin: auto;
    padding: 0 5px;
    border: 1px solid var(--cognac);
    text-align: center;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
}

.mission-statement h1 {
    text-align: center;
}

/* Section Two About the Owner */

.section-two-about {
    display: flex;
    justify-content: space-evenly;
    background-color: var(--pewter);
    padding: 10px;
    padding-top: 20px;
}
.owner-image {
    padding-top: 10px;
}
.owner-image img {
    border-radius: 219px 219px 0px 0px;
    width: 300px;
    transition: .3s;
}

.meet-owner {
    background-color: white;
    width: 60%;
    border: 1px solid white;
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 10px 10px 5px var(--blush);
    margin-left: 5px;
}

.meet-owner h1 {
    margin-bottom: 10px;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
    color: var(--cognac);
}

.meet-owner p {
    font-size: 1.25rem;
    margin-bottom: 0px;
}

/* Section Three Features */
.section-three-about {
    background-color: var(--pewter);
    text-align: center;
    padding: 20px;
}

.section-three-about h1 {
    color: var(--dusty-rose);
    font-size: 4rem;
}

/* Media Queries */

@media screen and (max-width: 1455px){
    .mission-statement-container {
        width: 40%;
        top: 70px;
        left: 30%;
    }
}

@media screen and (max-width: 1285px){
    .mission-statement-container {
        width: 50%;
        top: 40px;
        left: 27%;
    }
}

@media screen and (max-width: 1150px){
    .mission-statement-container {
        width: 65%;
        left: 20%;
        top: 20px;
    }

}

@media screen and (max-width: 960px){
    .mission-statement-container {
        width: 75%;
        left: 15%;
        top: 8px;
    }

    .mission-statement h1 {
        font-size: 2.25rem;
    }

    .mission-statement {
        font-size: 1rem;
    }

    .owner-image img {
        width: 250px;
    }

    .meet-owner h1 {
        font-size: 2.5rem;
    }
    
    .meet-owner p {
        font-size: 1rem;
    }
}


@media screen and (max-width: 770px){ 
    .section-one-about{
        background-color: var(--pewter);
        height: 600px;
    }

    .header-images {
        flex-direction: column;
    }

    .header-images img {
        width: auto;
    }

    .img-2 {
        display: none;
    }

    .mission-statement-container {
        width: 80%;
        left: 10%;
        top: 320px;
    }

    .owner-image {
        padding-top: 30px;
    }
    .owner-image img {
        width: 150px;
    }

    .meet-owner {
        width: 70%;
    }

    .meet-owner-header{
        display: flex;
        flex-direction: row;
    }

    .meet-owner-header h3 {
        padding-top: 10px;
        font-size: 1.25rem;
        margin-left: 20px;
    }

    .meet-owner h1 {
        font-size: 2rem;
    }
    
    /* .meet-owner p {
        font-size: .75rem;
    } */
}


@media screen and (max-width: 880px){ 

    .section-two-about {
        flex-direction: column;
    }

    .owner-image {
        text-align: center;
        padding-top: 0px;
    }

    .owner-image img {
        width: 80%;
    }

    .meet-owner {
        width: 90%;
        margin: auto;
        margin-top: 20px;
    }

    .meet-owner-header{
        flex-direction: column;
    }

    .meet-owner-header h3 {
        font-size: 1.75rem;
        margin-left: 0px;
    }
    
    .meet-owner h1 {
        margin-bottom: 10px;
        font-size: 3rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 100;
        color: #98806B;
    }
    
    .meet-owner p {
        font-size: 1.25rem;
    }

}

@media screen and (max-width: 620px){ 

    .mission-statement-container {
        top: 220px;
    }

}

@media screen and (max-width: 410px){ 
    .section-one-about{
        height: 650px;
    }
    .mission-statement-container {
        top: 190px;
    }
}

@media screen and (max-width: 320px){ 
    .section-one-about{
        height: 750px;
    }
    .mission-statement-container {
        top: 150px;
    }
}