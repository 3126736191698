.carousel-control-prev-icon {
    fill: var(--rose)
}

.carousel-section {
    background-color: var(--pewter);
    padding-top: 40px;
}

.carousel-section h1 {
    font-family: 'Petit Formal Script', cursive;
    padding: 20px 10px;
    /* font-family: 'League Script', cursive; */
    text-align: center;
    font-size: 3.25rem;
    color: var(--dusty-rose);
    font-weight: 200;
}

.customer-review-container {
    width: 75%;
    margin: auto;
    margin-bottom: 50px;
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
    box-shadow: 10px 10px 5px var(--blush);
}

.slide {
    display: flex;
}

.cr-image {
    overflow: hidden;
}


.review {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.review h3 {
    margin-bottom: 30px;
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
    color: #98806B;
}

.review-content {
    font-size: 1.25rem;
}

.client {
    font-size: 1.5rem;
    font-family: 'Cormorant Garamond', serif;
}



@media screen and (max-width: 1500px) {
    .customer-review-container {
        width: 90%;
    }

    .slide {
        height: auto;
    }

    .review {
        margin: auto 5%;
    }

    .review h3 {
        font-size: 2.15rem;
        margin-bottom: 10px;
    }



}

@media screen and (max-width: 1250px) {
    .cr-image {
        width: 40%;
    }

    .cr-image img {
        width: 100%;
    }

    .review {
        width: 60%;
    }

    .review h3 {
        font-size: 1.75rem;
    }

    .review-content {
        font-size: 1rem;
    }
}

@media screen and (max-width: 970px) {
    .cr-image {
        width: 30%;
    }

    .review {
        width: 70%;
    }

    .review h3 {
        font-size: 1rem;
        margin-bottom: 10px;
    }

    .review-content {
        font-size: .90rem;
        margin-bottom: 10px;
    }

    .client {
        font-size: .9rem;
        font-family: 'Cormorant Garamond', serif;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 900px) {
    .slide {
        flex-direction: column;
    }

    .customer-review-container {
        width: 60%;
    }

    .cr-image {
        width: 100%;
    }

    .review {
        width: 90%;
        margin: auto;
    }

    .review h3 {
        font-size: 2rem;
        margin: 15px;
        text-align: center;
    }

    .review-content {
        font-size: 1.25rem;
        text-align: center;
    }

    .client {
        text-align: center;
        font-size: 1.5rem;
        font-family: 'Cormorant Garamond', serif;
        margin-bottom: 30px;
    }

}

@media screen and (max-width: 700px) {
    .customer-review-container {
        width: 85%;
    }
}