/* Banner with three images and two tone background */
.banner {
    display: flex;
    justify-content: center;
    background: linear-gradient(var(--pewter) 50%, var(--blush) 50%);
}

/* Hi Babe Section */

.welcome-intro {
    background-color: var(--blush);
    width: 100%;
    padding: 40px;
    text-align: center;
    font-size: 1.45rem;
}

.quote {
    font-family: 'Petit Formal Script', cursive;
    text-align: center;
}

/* Starting at Decorative Quote and Services */
.decoration {
    font-size: 2.5rem;
    padding: 30px 20px;
    color: var(--dusty-rose);
    background-color: var(--pewter);
    margin-bottom: 0;
}

.home-services {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--pewter);
    width: 100%;
}

.hs-image {
    height: 550px;
    transition: .3s;
    border-radius: 10px;
    box-shadow: 10px 10px 5px var(--blush);
}

.hs-image img {
    height: 100%;
    width: auto;
}


.hs-content {
    padding-top: 5px;
    width: 50%;
}

.hs-content h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    text-align: center;
    font-size: 3rem;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray);
}

.hs-content p {
    padding: 20px 30px;
    font-size: 1.25rem;
    margin-bottom: 0px;
}

.hs-content h2 {
    font-size: 2.15rem;
    padding-top: 20px;
    color: var(--dusty-rose);
}

.hs-content ul li {
    font-size: 1.3rem;
    padding: 5px 0;
}

.blush-btn {
    margin-top: 20px;
    padding: 15px;
    background-color: var(--blush);
    width: 270px;
    border-radius: 30px;
    border: 1px solid var(--blush);
    font-size: 1.15rem;
    color: var(--gray);
}

.blush-btn:hover {
    opacity: 80%;
    color: var(--cognac);
}

/* Section 4 What We Offer */
.section-four h1 {
    background-color: var(--pewter);
    text-align: center;
    font-size: 3rem;
    color: var(--gray);
    padding-top: 20px;
    margin-bottom: 0;
}

.offer-card-container {
    background-color: var(--pewter);
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.offer-card {
    margin: 20px;
    padding: 20px;
    border: 1px solid white;
    width: 400px;
    /* height: 400px; */
    border-radius: 10px;
    box-shadow: 10px 10px 5px var(--blush);
    background-color: white;
}

.offer-card-heading {
    border-bottom: 2px solid var(--dusty-rose);
    padding-bottom: 20px;
    color: var(--gray);
}

.offer-card-heading h2 {
    font-size: 1.5rem;
}

.offer-card-heading .number {
    color: var(--dusty-rose);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 2.65rem;
    margin-right: 10px;
}

.offer-card-content {
    font-size: 1.25rem;
    padding: 25px;
}

/* Customer Reviews Carousel */
.ratings {
    display: flex;
    justify-content: center;
}

.ratings img {
    width: 40px;
}

/* CSS for this section is in the CRCarousel.css file in the page-components folder */

/* Media Query for Responsiveness */
@media screen and (max-width: 1100px) {
    .home-services {
        flex-direction: column;
    }

    .home-hair-services {
        flex-direction: column-reverse;
        padding-top: 20px;
    }

    .hs-content {
        width: 95%;
        text-align: center;
    }

    .hs-content h1 {
        padding: 20px 0px;
    }

}


@media screen and (max-width: 1320px) {
    .offer-card-container {
        flex-direction: column;
    }

    .offer-card {
        margin: auto;
        margin-top: 30px;
        width: 90%;
    }
}

@media screen and (max-width: 700px) {
    .welcome-intro {
        padding: 40px 20px;
    }

    .hs-image {
        display: flex;
        width: 90%;
        height: auto;
    }

    .hs-image img {
        width: 100%;
    }

    .hs-content p {
        padding: 20px 10px;
        font-size: 1.25rem;
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 500px) {

    .hs-content {
        padding-left: 0px;
        padding-right: 0px;
    }

    .hs-content p {
        text-align: center;
    }

    .hs-content ul {
        padding-left: 0;
    }

    .blush-btn {
        width: 100%;
    }

    .section-four h1 {
        padding: 25px 15px 0px 15px;
    }

    .offer-card {
        margin: auto;
        margin-top: 30px;
    }

}