footer {
    background-color: var(--pewter);
    padding-top: 20px;
}

.footer-content {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.socials {
    text-align: center;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.social-icon-container {
    display: flex;
    justify-content: center;
}

.social-icons {
    fill: var(--dusty-rose);
    width: 30px;
}

.social-icons:hover {
    fill: var(--cognac);
}

.ajb-small-icon {
    width: 25%;
}

.ajb-small-icon img {
    width: 100%;
}

/* .book-button button {
    margin: auto;
    margin-top: 15px;
} */

.footer-nav {
    width: 33%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-nav h3 {
    font-size: 2rem;
}

.footer-nav-links {
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.footer-nav-links li {
    font-size: 1.75rem;
    padding: 0px 10px;
}

.footer-nav-links li a {
    text-decoration: none;
    color: var(--gray);
}

.footer-nav-links li a:hover {
    color: var(--cognac);
}

.request-quote-btn{
    margin: 0px auto;
}

.request-quote-btn svg {
    fill: var(--dusty-rose);
    width: 25px;
    margin-left: 10px;
}

/* .buisness-hours {
    text-align: center;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buisness-hours h3 {
    font-size: 1.75rem;
}

.buisness-hours ul {
    padding: 0;
    font-size: 1.25rem;
    margin: 0px;
} */


Footer p {
    text-align: center;
}

@media screen and (max-width: 850px) {
    .footer-btn {
        width: 200px;
        margin-bottom: 30px;
    }
}


@media screen and (max-width: 685px) {
    .footer-content {
        width: 95%;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }

    .socials {
        width: 100%;
        margin-bottom: 30px;
    }

    .ajb-small-icon {
        width: 70%;
        margin-bottom: 20px;
    }

    .footer-nav {
        width: 90%;
    }

    .footer-nav-links li {
        font-size: 1.65rem;
        padding: 0px 10px;
    }

}