/* Gallery Header Section */
.section-one-gallery{
    position: relative;
}

.gallery-header-img {
    display: flex;
    width: 100%;
}

.gallery-header-img img {
    width: 100%;
    margin: auto;
    opacity: .8;
}

.gallery-header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    color: var(--pewter);
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: hsla(0,0%,5%,.38);
}

.gallery-header-text h1 {
    font-family: 'Petit Formal Script', cursive;
    font-weight: 400;
    font-size: 3rem;
}

.gallery-header-text h2 {
    font-weight: 900;
    font-size: 6rem;
}


/* Images Section */
.section-two-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--pewter);
}

.gallery-images {
    height: 400px;
}

.gallery-images img {
    height: 100%;
    padding: 2%;
}

/* Media Queries */
@media screen and (max-width: 1060px){
    .gallery-header-text {
        padding: 10px;
    }
}

@media screen and (max-width: 680px){

    .gallery-header-text h1 {
        font-weight: 600;
        font-size: 2rem;
    }
    
    .gallery-header-text h2 {
        font-weight: 900;
        font-size: 4rem;
    }

    /* .gallery-images {
        width: 45%;
    } */

}

@media screen and (max-width: 470px){

    .gallery-header-text h1 {
        font-weight: 400;
        font-size: 1rem;
    }
    
    .gallery-header-text h2 {
        font-weight: 800;
        font-size: 1.85rem;
    }

    .gallery-header-text h3 {
        font-size: 1rem;
    }

    .gallery-images {
        height: 390px;
    }

}

@media screen and (max-width: 270px) {
    .gallery-header-text {
        color: var( --cognac);
        position: relative;
        background-color: var(--blush);
    }

    .gallery-images {
        height: 250px;
    }
}