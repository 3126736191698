.section-one-services {
    background-color: var(--pewter);
    position: relative;
}

.service-header-image {
    width: 40%;
    opacity: .5;
}

.service-header-image img,
.service-header-image-two img {
    width: 100%;
}

.service-header-image-two {
    width: 30%;
    position: absolute;
    top: 30px;
    left: 20%;
}


.service-header-text {
    display: flex;
    flex-direction: column;
    width: 40%;
    position: absolute;
    top: 15%;
    right: 5%;
    text-align: center;
    padding: 10px;
    border-left: 1px solid var(--cognac);
}

.service-header-text h1 {
    font-size: 4.5rem;
}

.service-header-text h2 {
    font-size: 3rem;
}

.service-header-text p {
    font-size: 2rem;
}

/* Section Two Makeup Services */
.section-two-services {
    background-color: var(--blush);
}

.services-two-header,
.services-three-header {
    color: var(--dusty-rose);
    text-align: center;
    padding: 40px;
    font-size: 3.5rem;
}

.service-makeup-container,
.service-hair-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    text-align: left;
}

.service-images {
    width: 35%;
}

.service-images img {
    width: 100%;
    outline: 2px solid white;
    outline-offset: -10px;
}

.service-details {
    width: 60%;
    margin-bottom: 5px;
}

.cursive {
    font-family: 'Petit Formal Script', cursive;
}

.service-hair-details {
    width: 40%;
}

.hair-service-details p {
    font-size: 1.5rem;
}

.service-details p,
.service-details ul,
.service-hair-details p,
.service-hair-details ul {
    font-size: 1.25rem;
}

.section-three-services {
    background-color: var(--pewter);
}

/* Section four  */
.section-four-services {
    background-color: var(--pewter);
}

.policies-container {
    text-align: center;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid white;
    border-radius: 10px;
    box-shadow: 10px 10px 5px var(--blush);
    background-color: white;
}

.policies-container h1 {
    font-size: 3.5rem;
    color: var(--dusty-rose);
}

.policies-container p {
    font-size: 1.25rem;
    text-align: left;
    text-indent: 50px;
}



/* Media Queries */
@media screen and (max-width: 1510px) {
    .service-header-text h1 {
        font-size: 3.5rem;
    }

    .service-header-text h2 {
        font-size: 2.5rem;
    }

    .service-header-text p {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1200px) {
    .service-header-text {
        top: 5%;
    }

    .service-header-text h1 {
        font-size: 2.5rem;
    }

    .service-header-text h2 {
        font-size: 2rem;
    }

    .service-header-text p {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 900px) {
    .service-header-text {
        top: 10px;
    }

    .service-header-text h1 {
        font-size: 2rem;
    }

    .service-header-text h2 {
        font-size: 1.5rem;
    }

    .service-header-text p {
        font-size: 1rem;
    }
}


@media screen and (max-width: 770px) {

    .service-makeup-container {
        flex-direction: column;
    }

    .service-hair-container {
        flex-direction: column-reverse;
    }

    .service-images {
        width: 70%;
        margin-bottom: 20px;
    }

    .service-details,
    .service-hair-details {
        width: 90%;
        margin-bottom: 5px;
        text-align: center;
    }

    .service-hair-details p {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 705px) {
    .section-one-services {
        overflow: hidden;
    }

    .service-header-image {
        width: 100%;
        height: 400px;
        overflow: hidden;
    }

    .service-header-image-two {
        width: 60%;
        top: 50px;
        left: 20%;
    }

    .service-header-text {
        position: static;
        margin: auto;
        margin-top: 30%;
        width: 90%;
        border-top: 1px solid var(--cognac);
        border-left: 0px;
    }

}

@media screen and (max-width: 565px) {

    .service-header-text {
        margin-top: 18%;
    }

}