.section-one-contact {
    background-color: var(--pewter);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
}

.g-carousel{
    width: 43%;
}

.contact-header-text {
    width: 40%;
    height: 400px;
    text-align: center;
}

.location-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.location-icon {
    width: 35px;
    fill: var(--dusty-rose);
}

.location-details p {
    margin-top: 15px;
    font-size: 1.5rem;
}

/* Section Two Form  */
.section-two-contact{
    background-color: var(--blush);
    padding-bottom: 5%;
}

.section-two-contact h1{
    color: var(--dusty-rose);
    text-align: center;
    font-size: 4rem;
    font-family: 'Petit Formal Script', cursive;
    padding-top: 30px;
}

/* Process Section */

.process {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    padding: 40px 0px;
    background-color: var(--blush);
}

/* .process h2 {
    font-family: 'Montserrat', sans-serif;
} */

.next-steps {
    color: var(--dusty-rose);
    background-color: var(--blush);
    text-align: center;
    font-size: 4rem;
    font-family: 'Petit Formal Script', cursive;
    padding-top: 30px;
    margin-bottom: 0px;
}
.process p {
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
}

.process-card {
    width: 30%;
}
.process-card img {
    width: 60px;
}

/* Section Three FAQ */
 .section-three-contact {
    background-color: var(--blush);
    padding: 50px 10px;
}

.section-three-contact h1 {
    text-align: center;
    font-family: 'Petit Formal Script', cursive;
    color: var(--dusty-rose);
    font-size: 3rem;
}

details {
    margin: 30px auto;
    padding: 20px;
    border: 1px solid var(--pewter);
    width: 80%;
    /* height: 400px; */
    border-radius: 10px;
    box-shadow: 10px 10px 5px var(--dusty-rose);
    background-color: var(--pewter);
}

details summary {
    font-size: 1.75rem;
}

details > summary {
    list-style-type: none;
}

details p {
    font-size: 1.5rem;
    text-align: center;
    padding-top: 20px;
}


/* section four what we have to offer */
.section-four-contact {
    background-color: var(--blush);
}

.section-four-contact h1 {
    color: var(--dusty-rose);
    font-family: 'Petit Formal Script', cursive;
    font-size: 4rem;
    text-align: center;
}

.sf-card-container {
    display: flex;
    justify-content: space-evenly;
}

.sf-cards {
    width: 25%;
    height: 90%;
    text-align: center;
}

.sf-cards img {
    width: 100%;
    height: auto;
    border-radius: 219px 219px 0px 0px;
}

.sf-card-container h2 {
    text-align: center;
    height: 10%;
}

@media screen and (max-width: 900px) {
    .process {
        flex-direction: column;
        align-items: center;
    }
    
    .process-card {
        width: 90%;
        padding: 30px 0px;

    }
}


@media screen and (max-width:765px) {
    .section-one-contact {
        flex-direction: column;
    }

    .g-carousel{
        width: 90%;
        margin: auto;
    }

    .contact-header-text {
        width: 90%;
    }

    .sf-card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sf-cards {
        width: 70%;
    }

    .sf-card-container h2 {
        font-size: 2.5rem;
    }

    .next-steps {
        color: var(--dusty-rose);
        background-color: var(--blush);
        text-align: center;
        font-size: 3rem;
        font-family: 'Petit Formal Script', cursive;
        padding-top: 30px;
        margin-bottom: 0px;
    }
}